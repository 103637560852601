<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48"  >
            <a-col :md="4" :sm="24">
              <div style="height: 100px;width:75%;padding: 20px 20px;border-radius:50px;background: #d0caca">
                <p> 账户余额：{{account.money}} 元</p>
                <p>
                  <span style="font-size: 15px;color: #1d42ab"><a @click="$refs.accountBillForm.show(account)" >账户收益明细 </a></span>
                </p>
              </div>
              <a-divider type="vertical" />
            </a-col>

            <a-col :md="6" :sm="24" >
              <a-form-item label="审核状态">
                <a-select v-model="queryParam.status" placeholder="请选择">
                  <a-select-option :value="0">待审核</a-select-option>
                  <a-select-option :value="1">审核驳回</a-select-option>
                  <a-select-option :value="2">审核通过</a-select-option>
                  <a-select-option :value="3">取消</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="提现类型">
                <a-select v-model="queryParam.withdrawType" placeholder="请选择">
                  <a-select-option value="1">支付宝</a-select-option>
                  <a-select-option value="2">微信</a-select-option>
                  <a-select-option value="3">银行卡</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" :disabled="account.money == 0" @click="$refs.createForm.handleAdd(account)">
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:withdraw:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" >-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:withdraw:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <account-bill-list ref='accountBillForm' @ok='getList'/>
      <create-form ref='createForm' @ok="getList" @close="getList"/>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a v-if="record.status == 0" @click="handleCancel(record,true)">取消</a>
<!--          <a v-if="record.status !=0" @click="$refs.withModalForm.audit(record,false)">详情</a>-->

          <a v-if='record.status == 3' @click="handleDelete(record)" > 删除 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageWithdraw,listWithdraw,cancelWithDraw, delWithdraw ,getVillageAccount} from '@/api/user/withdraw'
import withdrawModal from './modules/WithdrawModal.vue'
import AccountBillList from '@/views/withdraw/record/modules/AccountBillList.vue'
import CreateForm from './modules/CreateForm.vue'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Withdraw',
  components: {
    withdrawModal,CreateForm,AccountBillList
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      account:[],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userName: null,
        accountName: null,
        bankName: null,
        status:null,
        withdrawType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
          width: 80,
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        // {
        //   title: '申请用户',
        //   dataIndex: 'userName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '提现金额(元)',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '提现方式',
          dataIndex: 'withdrawType',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == '1'){
              return "支付宝"
            }else if(value == '2'){
              return "微信"
            }else if(value == '3'){
              return "银行"
            }
            return "未知"
          }
        },
        // {
        //   title: '实际转账金额(元)',
        //   dataIndex: 'realMoney',
        //   ellipsis: true,
        //   align: 'center'
        // },

        {
          title: '提现账号',
          dataIndex: 'accountNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '提现账号姓名',
          dataIndex: 'accountName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '银行名称',
          dataIndex: 'bankName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开户行地址',
          dataIndex: 'bankAdress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '提现手续费(元)',
          dataIndex: 'serviceChargeNum',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 0){
              return "待审核"
            }else if(value == 1){
              return "审核驳回"
            }else if(value == 2){
              return "审核通过"
            }else if(value == 3){
              return "取消"
            }

          }
        },
        {
          title: '审核意见',
          dataIndex: 'auditOpinion',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getVillageAccount();
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {

    //账户数据
    getVillageAccount(){
      this.loading = true
      getVillageAccount().then(response => {
        this.account = response.data
        this.loading = false
      })
    },
    /** 查询用户提现列表 */
    getList () {
      this.loading = true
     pageWithdraw(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
      this.getVillageAccount();
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userName: null,
        accountName: null,
        bankName: null,
        status:null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleCancel(row){
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认取消所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          row.status = 3
          cancelWithDraw(row).then(res => {
            if (res.success) {
              that.$message.success(res.message);
              that.getList()
            } else {
              this.$message.warning(res.message)
            }

          }).finally(() => {
            this.submitLoading = false
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delWithdraw(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-withdraw/export', {
            ...that.queryParam
          }, `用户提现_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSH: function (record) {
      this.$refs.modalForm.edit(record);
    },
    handleInfo: function (record) {
      this.$refs.modalForm.info(record);
    }
  }
}
</script>
